
:root {
  --Red: hsl(1, 90%, 64%);
  --Blue: hsl(219, 85%, 26%);
  --White: hsl(0, 0%, 100%);
  --Very-light-grayish-blue: hsl(210, 60%, 98%);
  --Light-grayish-blue-1: hsl(211, 68%, 94%);
  --Light-grayish-blue-2: hsl(205, 33%, 90%);
  --Grayish-blue: hsl(219, 14%, 63%);
  --Dark-grayish-blue: hsl(219, 12%, 42%);
  --Very-dark-blue: hsl(224, 21%, 14%);
}
.container-listNotification-nhi {
  width: 100%;
  background-color: var(--Light-grayish-blue-1);
  border-radius: 0.5rem;
  overflow-y: hidden;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 15px;
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.header-notification-nhi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.notif_box {
  display: flex;
  align-items: center;
}
#notifes {
  background-color: var(--Blue);
  margin-left: 0.5rem;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: var(--White);
  font-weight: 800;
  border-radius: 0.5rem;
}
#mark_all {
  cursor: pointer;
}
#mark_all:hover {
  color: var(--Blue);
}
.tag-p-notification {
  color: var(--Dark-grayish-blue);
}
.main-notification-nhi {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  height: 80%;
}
.notif_card {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem;
}
.notif_card:hover{
  cursor:pointer;
  background-color:#8acafa;
}
.img-tag-notification-nhi{
  width: 50px;
}
.description{
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.strong-tag-notification-nhi{
  color: var(--Very-dark-blue);
  cursor: pointer;
}
.strong-tag-notification-nhi:hover{
  color: var(--Blue);
}
.unread{
  background-color:lightgrey;
}
.unread p:first-of-type::after{
  content: " ";
  background-color: var(--Red);
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}
.message_card{
  display: flex;
  align-items: center;

}
.message{
  padding: 1rem;
  border: 1px solid var(--Light-grayish-blue-2);
  border-radius: .3rem;
  cursor: pointer;
  margin: 0 0 0 5rem;
}
.message:hover{
  background-color: var(--Light-grayish-blue-1);
}
.chess_img{
  margin-left: auto;
}

@media screen and (max-width:550px) {
  .container-listNotification-nhi{
    margin: 0;
  }
}

.container-listNotification-nhi::-webkit-scrollbar {
  width: 10px;
}

.container-listNotification-nhi::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.container-listNotification-nhi::-webkit-scrollbar-thumb:hover {
  background: #555;

}
.see-all-button {
  display: flex;
  position: sticky;
  justify-content: center;
  padding: 1rem 0;
  background-color: var(--Light-grayish-blue-1);
}

.see-all-button button {
  background-color: var(--Blue);
  color: var(--White);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 800;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-bottom: 0;

}

.see-all-button button:hover {
  background-color: var(--Red);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

