.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1000;

  &.appear {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalContent {
    background-color: #e0eaea;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Thay đổi max-width để phù hợp với thiết bị nhỏ hơn */
    width: 550px; /* Kích thước mặc định cho modal */
    height: 400px;

    .topic {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      .topicElement {
        width: 150px;
        height: 150px;
        margin: 10px 40px;
        flex-basis: 28%;
        border-radius: 50%;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
      }

      .spring {
        background-image: url("./spring.jpg");
      }

      .summer {
        background-image: url("./summer.jpg");
      }

      .autumn {
        background-image: url("./autumn.jpg");
      }

      .winter {
        background-image: url("./winter.jpg");
      }
    }

    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      button {
        width: 100px;
        height: 40px;
        border: none;
        border-radius: 5px;
        background: var(--action-color);
        color: var(--button-char-color);
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          background: var(--button-bg-hover);
        }
      }
    }
  }
}
