#main{
    margin-top: 124px;
    padding: 4rem 1.4rem;
    h3{
        text-align: center;
        text-transform: uppercase;
    }
}
.mainLookOrder{
    display: flex;
    justify-content: center;
    align-items: center;
    .form{
        width: 100%;
        max-width: 500px;
        input{
            display: block;
            width: 100%;
            padding: 10px 14px;
            margin-top: 10px;
            outline: none;
        }
        button{
            margin-top: 10px;
            padding: 8px;
            background-color: #3091dc;
            border: none;
            color: white;
            border-radius: 4px;
            cursor: pointer;

        }
    }
}