/* ProductDetailModal.module.scss */

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s ease;


  &.open {
    display: flex;
  }

  .modalContent {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    max-width: 800px;
    width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 50px;

    .info {
      padding: 16px;
      overflow-y: auto;

      .title {
        font-weight: bold;
        margin-right: 8px;
        font-size: 16px;
      }

      span {
        font-size: 16px;
        color: #333;
      }

      .images {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 8px 16px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .modalContent {
      max-width: 90%;

      .info {
        .title {
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }

        .images {
          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .button {
        button {
          padding: 6px 12px;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .modalContent {
      .info {
        padding: 12px;

        .title {
          font-size: 12px;
        }

        span {
          font-size: 12px;
        }

        .images {
          img {
            width: 60px;
            height: 60px;
          }
        }
      }

      .button {
        button {
          padding: 4px 8px;
          font-size: 12px;
        }
      }
    }
  }
}
