.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.open {
    display: flex;
  }

  .modalContent {
    background-color: #eff4f4;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Thay đổi max-width để phù hợp với thiết bị nhỏ hơn */
    width: 550px; /* Kích thước mặc định cho modal */
    height: 300px;

    .modalHeader {
      text-align: center;
      width: 100%;
      height: 30%;

      svg {
        width: 70px;
        height: 70px;
        color: red;
      }
    }

    .modalBody {
      width: 100%;

      div {
        width: 100%;
        padding: 10px 20px;

        label {
          padding-right: 20px;
          min-width: 100px;
          font-weight: 600;
          color: var(--app-content-main-color);
        }
        span {
          min-width: 150px;
        }
      }

      .modalTitle {
        text-align: center;

        p {
          color: var(--app-content-main-color);
          padding: 0 20px;
          font-size: 20px;
          font-weight: 550;
        }
      }
    }

    .modalFooter {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 70px;
        height: 35px;
        border-radius: 10px;
        border: none;
        font-weight: 600;
        position: relative;
        color: #292626;
        margin: 10px;
      }

      .acceptDelete {
        background-color: rgba(246, 29, 29, 0.78);
        &:hover {
          background-color: #f62e2e;
          transition: 0.3s ease-in-out;
        }
      }

      .cancel {
        background-color: #cdcdcd;
        &:hover {
          background-color: #aca6a6;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}
