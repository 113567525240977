.overlay-nhi {
  position: fixed;
  top: 31px;
  right: 16%;
  max-width: 350px;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.notification-content-header-nhi {
  padding: 20px;
  border-radius: 8px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
}