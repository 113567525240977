@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.form {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--content-bg);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input, .formGroup select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pricingContainer {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.pricingRow {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.addButton {
  position: absolute;
  right: 0;
  top: -35px;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addButton:hover {
  background-color: #0056b3;
}

.removeButton {
  position: absolute;
  top: 2px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.removeButton:hover {
  background-color: #c82333;
}

.buttonWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.submitButton {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.submitButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .form {
    padding: 10px;
  }

  .pricingRow {
    padding: 5px;
  }

  .addButton {
    font-size: 14px;
  }

  .submitButton {
    font-size: 14px;
  }
}
