.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.open {
    display: flex;
  }

  .modalContent {
    background-color: #d2eaea;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Thay đổi max-width để phù hợp với thiết bị nhỏ hơn */
    width: 400px; /* Kích thước mặc định cho modal */
    height: 650px;

    .backgroundImage {
      border-radius: 15px 15px 0 0;
      width: 100%;
      height: 100px;
    }
    .boxInfo {
      width: 100%;
      height: calc(650px - 100px);
      background-color: white;
      border-radius: 0 0 15px 15px;
      .avatar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          position: absolute;
          top: -70px;
        }
      }

      .info {
        width: 100%;
        padding-top: 40px;
        div {
          width: 100%;
          padding: 10px 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .title {
            color: var(--app-content-main-color);
            font-size: 16px;
            font-weight: 600;
            width: 130px;
          }

          span {
            color: var(--app-content-main-color);
            font-size: 16px;
            font-weight: 500;
          }
        }

        .status {
          span {
            color: #37d31f;
            cursor: pointer;
          }
        }

        .email {
          span {
            color: #1743e4;
            cursor: pointer;
          }
        }
        .phoneNumber{
          span {
            color: #1743e4;
            cursor: pointer;
          }
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        button {
          width: 100px;
          height: 40px;
          border: none;
          border-radius: 5px;
          background: var(--action-color);
          color: var(--button-char-color);
          margin: 5px;

          &:hover {
            background: var(--button-bg-hover);
          }
        }

        .enable {
          background-color: #29b629;
          color: var(--button-char-color);
          &:hover {
            background: green;
          }
        }
      }
    }
  }
}
