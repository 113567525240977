#container {
  width: 100%;
  height: 100%;

  .header {
    z-index: 2;
    width: 100%;
    height: 17vh;
    position: relative;
    background-image: linear-gradient(rgb(255, 240, 218), rgb(255, 255, 255));
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-brand {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        margin-left: 50px;
        width: 100px;
        height: auto;
        border-radius: 50%;
      }

      span {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .middle-part {
      width: 30%;
    }
    .right-part {
      width: 20%;
      a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
        background: linear-gradient(to right, #30d0d0 0%, #0c86ea 100%);
        background-clip: text;
        color: transparent;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 83vh;
    background-image: linear-gradient( 109.6deg, rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% );
    .box {
      width: 60%;
      display: flex;
      height: 80%;
      box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;
      border-radius: 10px;
      overflow: hidden;
      .form-box {
        position: relative;
        display: flex;
        height: 100%;
        width: 50%;
        transition: all 0.8s;
        transform: translateX(0);
        background-color: #fff;
        z-index: 1;
        overflow: hidden;
        .form {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          h3 {
            color: #222;
            font-size: 40px;
          }
          span {
            color: #3a3838;
          }

          .form-content {
            width: 70%;
          }

          #form_input{
            position: relative;
            width: 100%;


            .type {
              width: 100%;
              height: 48px;
              background-color: #EEEDEF;
              margin: 30px 0;
              position: relative;
            }
            input {
              width: 100%;
              height: 100%;
              padding: 0 10px;
              font-size: 16px;
              border: none;
              outline: none;
              background-color: transparent;
            }
            input::placeholder {
              color: #A9A8A9;
            }

            svg {
              position: absolute;
              top: 16px;
              right: 10px;
            }
            .popup {
              width: 250px;
              height: 80px;
              border: 2px solid #a89090;
              position: absolute;
              top: -15%;
              left: 10%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #EEEDEF;
              border-radius: 1.125rem 1.125rem 1.125rem 0;
              z-index: 1;

              .validate-error {
                text-align: left;
                padding: 10px;
                color: #da639f;
                font-size: 16px;
                font-weight: 600;
              }
            }

            .remember-me-and-forgot {
              margin-bottom: 20px;
              font-weight: 500;
              label {
                display: inline;
                input {
                  width: 20px;
                  text-align: center;
                }
                margin-right: 20px;
              }
              a {
                text-decoration: none;
                color: #5a5555;
              }
            }
          }
        }
        .form.sign_up {
          transition: all 1.15s;
          z-index: 1;
          opacity: 0;
          background-color: #fff;
        }
        .form.sign_in {
          opacity: 1;
          transition: all 1.15s;
          z-index: 5;
          background-color: #fff;
        }
      }

      .overlay {
        display: flex;
        height: 100%;
        width: 50%;
        transition: all 0.8s;
        transform: translateX(0);
        background-image: url("../../assets/images/login-background2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        overflow: hidden;
        opacity: 1;
        .page {
          height: 100%;
          min-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          transition: all 0.5s;
          opacity: 1;
          h3 {
            color: #fff;
            font-size: 30px;
            margin-bottom: 15px;
          }
          p {
            color: #fff;
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 24px;
          }
        }
      }
      .btn {
        display: inline-block;
        outline: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 550;
        background-color: transparent;
        padding: 12px 45px;
        border-radius: 22px;
        border: 1.5px solid #fff;
        cursor: pointer;
      }
      .bkg {
        background-color: #FF4167;
      }

    }
    .box.active{
      .form-box {
        transform: translateX(100%);
        .form.sign_in {
          z-index: 1;
          opacity: 0;
        }
        .form.sign_up {
          z-index: 5;
          opacity: 1;
        }
      }

      .overlay {
        transform: translateX(-100%);
        .page.page_signIn {
          opacity: 0;
          margin-left: -100%;
        }
        .page.page_signUp {
          margin-left: 0%;
          background-image: url("../../assets/../assets/images/login-background2.jpg");
          background-repeat: no-repeat;
          background-size: cover;

        }
      }
    }
  }
  @media screen and (max-width: 1200px){
    .content {
      .box {
        width: 80%;
        height: 80%;
      }
    }
  }
  @media screen and (max-width: 1200px) and (max-height: 468px) {

    .content {
      height: 100vh;
      .box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
  @media screen and (max-width: 1024px){
    .header {
      .logo-brand {
        width: 50%;
        img {
          margin-left: 10px;
          width: 150px;
          height: auto;
        }
        span {
          font-size: 22px
        }
      }
      .middle-part {
        width: 20%;
      }
      .right-part {
        width: 30%;
        a {
          font-size: 18px;
        }
      }
    }
    .content {
      .box {
        width: 80%;
        height: 80%;
        .form-box {
          width: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) and (min-height: 1200px){
    .content {
      .box {
        width: 100%;
        height: 70%;
        border-radius: 0;
        box-shadow: none;
        .form-box {
          width: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) and (max-height: 600px){
    .content {
      .box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
        .form-box {
          width: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 992px){
    .content {
      .box {
        width: 80%;
        height: 70%;
        box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;
        border-radius: 10px;
        .form-box {
          width: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 992px) and (min-height: 1025px){
    .header {
      height: 13vh;
    }
    .content {
      .box {
        width: 100%;
        height: 60%;
        border-radius: 0;
        .form-box {
          width: 50%;
        }

      }

    }
  }

  @media screen and (max-width: 992px) and (max-height: 468px){
    .content {
      .box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
        .form-box {
          width: 50%;
        }
        .overlay {
          display: block;
        }
      }

    }
  }

  @media screen and (max-width: 768px){
    .header {
      z-index: 2;
      .logo-brand {
        width: 50%;
        img {
          margin-left: 10px;
          width: 150px;
          height: auto;
        }
        span {
          font-size: 22px
        }
      }
      .middle-part {
        width: 20%;
      }
      .right-part {
        width: 30%;
        a {
          font-size: 18px;
        }
      }
    }
    .content {
      .box {
        width: 100%;
        border-radius: 0;
        .form-box {
          width: 50%;
        }

      }
    }

  }
  @media screen and (max-width: 768px) and (max-height: 468px){
    .content {
      height: 100vh;
      .box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
        .form-box {
          width: 50%;
        }

      }
    }
  }
  @media screen and (max-width: 680px){
    .content {
      .box {
        width: 70%;
        height: 90%;
        box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;
        border-radius: 10px;
        .form-box {
          width: 100%;
        }
        .overlay {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 680px) and (max-height: 468px){
    .content {
      .box {
        width: 100%;
        height: 100%;
        box-shadow: none;
        border-radius: 0;
        .form-box {
          width: 50%;
        }
        .overlay {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 480px){
    .header {
      .logo-brand {
        width: 40%;
        img {
          margin-left: 10px;
          width: 150px;
          height: auto;
        }
        span {
          display: none;
          font-size: 24px
        }
      }
      .middle-part {
        width: 20%;
      }
      .right-part {
        width: 40%;
        a {
          font-size: 18px;
        }
      }
    }

    .content {
      .box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
        .form {
          .form-content {
            width: 80%!important;
          }
        }
      }
    }
  }
  /* -----------------------------CSS cho phần Footer (phần cuối trang web) ---------------------------------*/
  #footer {
    background-color: #fafafa;
    border-top: 1px solid #ccc;
  }
  #footer .footer-main {
    padding: 3.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #footer .footer-main .item {
    min-width: 25%;
  }
  #footer .footer-main .item ul li {
    margin: 14px 0;
  }
  #footer .footer-main .item:nth-child(3) ul + ul {
    display: flex;
  }
  #footer .footer-main .item:nth-child(3) ul + ul li {
    margin: 0;
  }
  #footer .footer-main .item:nth-child(3) ul + ul li svg {
    border: 1px solid black;
    border-radius: 4px;
    background-color: #ccc;
    width: 32px;
    height: 32px;
  }
  #footer .footer-main .item:nth-child(3) ul + ul li + li {
    margin-left: 16px;
  }
  #footer .footer-main .item:nth-child(4) ul + h3 + ul {
    display: flex;
  }
  #footer .footer-main .item:nth-child(4) ul + h3 + ul li + li {
    margin-left: 8px;
  }
  #footer .footer-main .item:nth-child(4) ul li label input, #footer .footer-main .item:nth-child(4) ul li label button {
    padding: 6px 8px;
    outline: none;
    border: 1px solid #ccc;
  }
  #footer .footer-main .item:nth-child(4) ul li label button {
    background-color: orangered;
    color: white;
    border: none;
  }
  #footer .footer-bottom {
    background-color: #2d2d2d;
    text-align: center;
  }
  #footer .footer-bottom p {
    min-height: 55px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@keyframes Show {
  0%, 49.999% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes Hidden {
  0%, 49.999% {
    opacity: 1;
    z-index: 5;
  }
  100% {
    opacity: 0;
    z-index: 1;
  }
}