#main{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    background-color: white;
    .box{
        width: 100%;
        max-width: 400px;
        img{
            width: 100%;
            aspect-ratio: 1/1;
        }
        a{
            display: block;
            text-align: center;
            border: 1px solid #ccc;
            padding: 4px;
            border-radius: 4px;
            background-color: #fef1d7;
            color: black;
            &:hover{
                background-color: #ffdc95;
            }
        }
    }
}