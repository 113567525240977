#footer{
    background-color: #fafafa;
    border-top: 1px solid #ccc;
    .footerMain{
        a{
            color: black;
        }
        padding: 3.2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item{
            min-width: 25%;
            ul{
                li{
                    margin: 14px 0;
                }
            }
        }
        .item:nth-child(3){
            ul+ul{
                display: flex;
                li{
                    margin: 0;
                    svg{
                        border: 1px solid black;
                        border-radius: 4px;
                        background-color: #ccc;
                        width: 32px;
                        height: 32px;
                    } 
                }
                li+li{
                    margin-left: 16px;
                    
                }
            }
        }
        .item:nth-child(4){
            ul+h3+ul{
                display: flex;
                li+li{
                    margin-left: 8px;
                }
            }
            ul{
                li{
                    label{
                        input,button{
                            padding: 6px 8px;
                            outline: none;
                            border: 1px solid #ccc;
                        }
                        button{
                            background-color: orangered;
                            color: white; 
                            border: none;  
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
    .footerBottom{
        background-color: #2d2d2d;
        text-align: center;
        p{
            min-height: 55px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 768px) {
    #footer {
        .footerMain{
            padding: 1.4rem;
        }
    }

}